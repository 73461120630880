<template>
  <div class="container-page big">
    <HeaderBlock title="Действующие меры защиты внутреннего рынка">
      <div class="header-block-measure__interaction">
        <div class="header-block-measure__interaction-left">
          <ButtonComponent mod="gradient-bg" class="button-redesign--mobile-full" @click="editCard(0)"
            >Создать</ButtonComponent
          >
          <ButtonComponent mod="gradient-bg" class="button-redesign--mobile-full" @click="clearFilter"
            >Очистить фильтр</ButtonComponent
          >
        </div>
        <div class="header-block-measure__interaction-right">
          <ButtonComponent mod="gradient-bg" class="button-redesign--mobile-full" @click="exportExcel"
            >Экспорт</ButtonComponent
          >
        </div>
      </div>
      <div class="header-block-measure__interaction">
        <div class="header-block-measure__interaction-left">
          <div class="change-status-block">
            <p class="change-status-block__title">Изменение статуса</p>
            <div class="change-status-block__wrapper-select">
              <div class="change-status-block__select">
                <SelectMultiComponent
                  v-model="selectedStatusId"
                  :options="getStatusOptions"
                  placeholder="Не указано"
                  modifier="rectangular"
                  classSelect="visible-clear"
                />
              </div>
              <div class="change-status-block__icon">
                <IconComponent @click="changeStatus" name="add_icon" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </HeaderBlock>
    <div class="table-shadow">
      <div class="table-container-base table-container-base--sort js-table-arrow">
        <table>
          <thead>
            <tr>
              <th class="column-icon"></th>
              <th class="column-46">
                <input type="checkbox" v-model="selectAll" :value="true" />
              </th>
              <th class="sort width-226">Товар</th>
              <th class="sort width-226">Тип меры</th>
              <th class="sort width-226">Коды ТН ВЭД ЕАЭС</th>
              <th class="sort width-226">Страна-экспортер/Группа стран</th>
              <th class="sort width-226">Дата начала действия</th>
              <th class="sort width-226">Дата окончания действия</th>
              <th class="sort width-226">Решение Коллегии ЕЭК</th>
              <th class="sort width-226">Компании-заявители</th>
              <th class="sort width-226">Страна базирования компаний-заявителей</th>
              <th class="sort width-226">Компании, поддерживающие заявление</th>
              <th class="sort width-226">Страна базирования компаний, поддерживающих заявление</th>
              <th class="sort width-226">Вето</th>
              <th class="sort width-226">Размер пошлины/ценовые обязательства</th>
              <th class="sort width-226">Статус</th>
            </tr>
            <tr>
              <th></th>
              <th></th>
              <th>
                <ComponentInput
                  placeholder="Товар"
                  mod="filter-small"
                  v-model="filter.tnvedGroupName"
                  clearButton
                  @onClear="filter.tnvedGroupName = ''"
                />
              </th>
              <th>
                <SelectMultiComponentCheckbox
                  v-model="filter.measureKindIds"
                  :options="measureKinds"
                  trackBy="text"
                  labelOptions="text"
                  valueProp="id"
                  placeholder="Выберите"
                  mod="filter-small"
                />
              </th>
              <th>
                <ComponentInput
                  placeholder="Код"
                  mod="filter-small"
                  v-model="filter.tnvedCodes"
                  clearButton
                  @onClear="filter.tnvedCodes = ''"
                />
              </th>
              <th>
                <div class="column-select">
                  <SelectMultiComponent
                    v-model="filter.countryExporterIds"
                    :options="getCountriesMultiSelect"
                    placeholder="Экспортеры"
                    modifier="filter-small"
                    classSelect="visible-clear"
                  />
                  <SelectMultiComponent
                    v-model="filter.countryGroupId"
                    :options="countryGroups"
                    placeholder="Группы стран"
                    modifier="filter-small"
                    classSelect="visible-clear"
                  />
                </div>
              </th>
              <th>
                <div class="horizontally-date">
                  <dateComponent v-model="filter.beginDateFrom" modClass="filter-small" />
                  <dateComponent v-model="filter.beginDateTo" modClass="filter-small" />
                </div>
              </th>
              <th>
                <div class="horizontally-date">
                  <dateComponent v-model="filter.endDateFrom" modClass="filter-small" />
                  <dateComponent v-model="filter.endDateTo" modClass="filter-small" />
                </div>
              </th>
              <th>
                <ComponentInput
                  placeholder="Решение"
                  mod="filter-small"
                  v-model="filter.decision"
                  clearButton
                  @onClear="filter.decision = ''"
                />
              </th>
              <th>
                <ComponentInput
                  placeholder="Компании"
                  mod="filter-small"
                  v-model="filter.companyApplicants"
                  clearButton
                  @onClear="filter.companyApplicants = ''"
                />
              </th>
              <th>
                <SelectMultiComponent
                  v-model="filter.countryApplicantIds"
                  :options="getCountriesMultiSelect"
                  placeholder="Выберите"
                  modifier="filter-small"
                  classSelect="visible-clear"
                />
              </th>
              <th>
                <ComponentInput
                  placeholder="Компании"
                  mod="filter-small"
                  v-model="filter.companySupportives"
                  clearButton
                  @onClear="filter.companySupportives = ''"
                />
              </th>
              <th>
                <SelectMultiComponent
                  v-model="filter.countrySupportiveIds"
                  :options="getCountriesMultiSelect"
                  placeholder="Выберите"
                  modifier="filter-small"
                  classSelect="visible-clear"
                />
              </th>
              <th>
                <ComponentInput
                  placeholder="Вето"
                  mod="filter-small"
                  v-model="filter.veto"
                  clearButton
                  @onClear="filter.veto = ''"
                />
              </th>
              <th>
                <ComponentInput
                  placeholder="Размер пошлины"
                  mod="filter-small"
                  v-model="filter.dutyAmount"
                  clearButton
                  @onClear="filter.dutyAmount = ''"
                />
              </th>
              <th>
                <SelectMultiComponent
                  v-model="filter.isActive"
                  :options="getStatusOptions"
                  placeholder="Выберите"
                  modifier="filter-small"
                  classSelect="visible-clear"
                />
              </th>
            </tr>
          </thead>
          <TableBody :itemsBody="page.items" :isLoading="isLoading">
            <tr v-for="item in page.items" :key="item.id" @dblclick="editCard(item.id)">
              <td>
                <div class="wrap-icon">
                  <IconComponent class="icon--full-svg" @click="editCard(item.id)" name="edit" />
                  <IconComponent class="icon--full-svg delete" @click="deleteCard(item.id)" name="delete-not-fill" />
                </div>
              </td>
              <td>
                <input type="checkbox" v-model="selectedIds" :value="item.id" />
              </td>
              <td>{{ item.tnvedName ? item.tnvedName : item.tnvedGroup?.name }}</td>
              <td>{{ item.measureKind?.name }}</td>
              <td>{{ item.tnveds?.map((x) => x.code)?.join(', ') }}</td>
              <td>
                {{ item.countryExporters?.map((x) => x.name)?.join(', ')
                }}{{ item.countryGroup ? '/' + item.countryGroup?.name : '' }}
              </td>
              <td>{{ $momentFormat(item.beginDate, 'DD.MM.YYYY') }}</td>
              <td>{{ $momentFormat(item.endDate, 'DD.MM.YYYY') }}</td>
              <td>{{ item.decision }}</td>
              <td>{{ item.companyApplicants }}</td>
              <td>{{ item.countryApplicants?.map((x) => x.name)?.join(', ') }}</td>
              <td>{{ item.companySupportives }}</td>
              <td>{{ item.countrySupportives?.map((x) => x.name)?.join(', ') }}</td>
              <td>{{ item.veto }}</td>
              <td>{{ item.dutyAmount }}</td>
              <td>{{ item.isActive ? 'действ.' : 'не действ.' }}</td>
            </tr>
          </TableBody>
        </table>
      </div>
    </div>

    <PaginationRedesigned
      v-if="page.pagesTotal > 1"
      :page-count="page.pagesTotal"
      :current-page="page.pageNumber"
      @change="onPageChange($event, false)"
      @more="onPageChange($event, true)"
    />
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import { debounce } from 'lodash';

  import ComponentInput from '@/common/components/ComponentInput';
  import dateComponent from '@/common/components/dateComponent.vue';
  import ButtonComponent from '@/common/components/redesigned/ButtonComponent.vue';
  import PaginationRedesigned from '@/common/components/redesigned/PaginationRedesigned';
  import SelectMultiComponentCheckbox from '@/common/components/redesigned/SelectRedesigned/SelectMultiComponentCheckbox.vue';
  import TableBody from '@/common/components/redesigned/TableBody';
  import SelectMultiComponent from '@/common/components/SelectMultiComponent.vue';
  import Constants from '@/common/constants';
  import removeEmptyValuesFromObject from '@/common/mixins/removeEmptyValuesFromObject';
  import IconComponent from '@/common/ui/IconComponent';

  import DictionaryApi from '@/modules/nsi/api/dictionary';

  import Api from '../api/marketdefence';
  import HeaderBlock from '../components/HeaderBlock';

  import { DIRECTIVES_ACTIONS_NAME, DIRECTIVES_GETTERS_NAME } from '@/store/directives';

  export default {
    name: 'MarketDefenceRegistry',
    components: {
      IconComponent,
      HeaderBlock,
      ButtonComponent,
      SelectMultiComponent,
      PaginationRedesigned,
      TableBody,
      ComponentInput,
      dateComponent,
      SelectMultiComponentCheckbox,
    },
    mixins: [removeEmptyValuesFromObject],
    data() {
      return {
        page: {},
        request: {
          pageNumber: 1,
          pageSize: Constants.pageSize,
        },
        filter: {
          tnvedGroupIds: [],
          tnvedGroupName: '',
          measureKindIds: [],
          tnvedCodes: '',
          beginDateFrom: null,
          beginDateTo: null,
          endDateFrom: null,
          endDateTo: null,
          decision: '',
          companyApplicants: '',
          companySupportives: '',
          veto: '',
          dutyAmount: '',
          tnvedIds: [],
          countryExporterIds: [],
          countryApplicantIds: [],
          countrySupportiveIds: [],
          isActive: null,
          countryGroupId: null,
        },
        measureKinds: [],
        isLoading: false,

        selectedIds: [],
        selectAll: false,
        selectedStatusId: null,
      };
    },
    created() {
      this.loadDictionaries();
      this.loadPage(false);
    },
    computed: {
      ...mapGetters({
        getCountriesMultiSelect: DIRECTIVES_GETTERS_NAME.getCountriesMultiSelect,
        countryGroups: DIRECTIVES_GETTERS_NAME.getGroupsCountriesMultiSelect,
      }),

      getStatusOptions() {
        return Constants.statusOptions.map((i) => ({ value: i.id, label: i.text }));
      },
    },
    methods: {
      ...mapActions({
        countriesGet: DIRECTIVES_ACTIONS_NAME.countriesGet,
        getGroupsCountries: DIRECTIVES_ACTIONS_NAME.getGroupsCountries,
      }),

      loadPage(append) {
        const dataFilter = this.objectFormatter(this.filter);
        if (dataFilter?.isActive) {
          dataFilter.isActive = dataFilter.isActive === 1;
        }
        this.isLoading = !append;
        Api.search({
          ...this.request,
          ...dataFilter,
        })
          .then((response) => {
            if (!append) {
              this.page = {};
              this.$nextTick(() => {
                this.page = response.data;
              });
            } else if (response.data) {
              this.page.pageNumber = response.data.pageNumber;
              this.page.items = this.page.items.concat(response.data.items);
            }
            this.isLoading = false;
          })
          .catch(() => {
            this.isLoading = false;
          });
      },

      loadDictionaries() {
        DictionaryApi.getItems({ dictName: 'typeMeasure', pageNumber: 1, pageSize: 0 })
          .then((response) => {
            this.measureKinds = response.data.items.map((x) => {
              return { text: x.name, id: x.id };
            });
          })
          .catch((error) => {
            console.error(error?.response?.data);
          });
        this.countriesGet();
        this.getGroupsCountries();
      },

      editCard(id) {
        this.$router.push({ name: 'MarketDefenceCard', params: { id: id, action: 'edit' } });
      },

      deleteCard(id) {
        Constants.alert.fire(Constants.confirmOptions.delete).then((res) => {
          if (res.isConfirmed) {
            Api.delete(id)
              .then((response) => {
                console.log(response);
                Constants.alert.fire('Удаление', 'Запись удалена', 'success');
                this.loadPage();
              })
              .catch((error) => {
                Constants.alert.fire(
                  'Удаление',
                  error.response && error.response.data ? error.response.data : 'Запись не удалена.',
                  'error',
                );
              });
          }
        });
      },

      onPageChange(pageNumber, addToExist) {
        this.request.pageNumber = pageNumber;
        this.loadPage(addToExist);
      },

      changeFilter: debounce(function () {
        this.request.pageNumber = 1;
        this.loadPage(false);
      }, 500),

      exportExcel() {
        Api.exportExcel(this.filters);
      },

      clearFilter() {
        this.filter = {};
        this.changeFilter();
      },

      changeStatus() {
        if (!this.selectedIds.length || !this.selectedStatusId) {
          return;
        }
        Api.changeStatusMulti({
          ids: this.selectedIds,
          statusId: this.selectedStatusId,
        })
          .then(() => {
            Constants.alert.fire('Изменение статуса', `Статус записей успешно изменен.`, 'success');
            this.clearSelection();
            this.loadPage();
          })
          .catch((error) => {
            Constants.alert.fire('Изменение статуса', Constants.commonError, 'error');
            console.error(error?.response?.data);
          });
      },
      clearSelection() {
        this.selectedIds = [];
      },
    },
    watch: {
      selectAll(value) {
        if (value) {
          this.selectedIds = [];
          this.selectedIds.push(...this.page.items.map((x) => x.id));
        } else {
          this.clearSelection();
        }
      },

      filter: {
        handler() {
          this.changeFilter();
        },
        deep: true,
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '../styles/index.scss';
  @import '@/assets/theme/default/table/table-base.scss';
</style>
